.footer {
  position: fixed;
  border-top: 2px solid #d1d8ff4a;
  left: 0;
  bottom: 0;
  font-size: 12px;
  width: 100%;
  background-color: #e8e8e9;
  /* color: white; */
  /* padding: 8px; */
  text-align: center;
  z-index: 9999999999999999;
}
