.project-name {
  font-size: 20px;
  color: #0c3560;
}

a {
  color: white;
}

a:hover {
  color: #db242b;
  text-decoration: none;
}

.nav__bg {
  background: #e3f1fe !important;
  color: black;
  border-bottom: 1px solid #1c22434a;
  font-family: "Lato", sans-serif;
  width: 100%;
}

a {
  color: "#1890ff";
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0rem;
}

.mr-auto.navbar-nav {
  background: #ebe9e9;
}

Nav.mr-auto:hover {
  color: red;
}
